export default {
  USCustomary:
    [
      {
        title: 'Most Likely Cracking DM',
        fileName: 'mostLikelyCrackingDMProb1Example.json',
      },
      {
        title: 'Most Likely Thinning DM',
        fileName: 'mostLikelyThinningDMProb1Example.json',
      },
      {
        title: 'Damage Rate Calculation given Chloride SCC - External DM',
        fileName: 'chlroideSCCExternalProb2Example.json',
      },
      {
        title: 'Damage Rate Calculation given Tank Bottom Corrosion DM',
        fileName: 'tankBottomProb2Example.json',
      },
      {
        title: 'Damage Rate NH4Cl Corrosion - Deliquescence',
        fileName: 'deliquescence_low_CR.json',
      },
      {
        title: 'Damage Rate NH4Cl Corrosion - Condensation',
        fileName: 'condensation_high_CR.json',
      },
      {
        title: 'Damage Rate Sulfidation - CS - Moderate Temp and Sulfur',
        fileName: 'sulfidationProb2CSModerateInputs.json',
      },
      {
        title: 'Damage Rate Sulfidation - 4-6Cr - H2S - Low Temp',
        fileName: 'sulfidationProb24CrH2S.json',
      },
    ],
  Metric: [],
}
